import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as t from 'io-ts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  isRealValue,
  ModuleSettings,
  nullable,
  schemaValidationPipe,
  storedAnswerSchema,
  StoredAnswersType,
  StoredAnswerType,
} from '@tr-common';

import {
  Consent,
  ConsentBody,
  consentBodySchema,
  consentSchema,
  ConsentSignType,
  consentStatusSchema,
  ConsentStatusType,
  SignedConsent,
  signedConsentSchema,
} from '../../consent/models';
import {
  REGISTRY_ADMIN_URL,
  REGISTRY_API_URL,
  REGISTRY_APPLICATION,
  REGISTRY_PARTICIPANT_URL,
  REGISTRY_PUBLIC_URL,
} from '../../global/tokens';
import {
  BEStudyProcedure,
  beStudyProcedureSchema,
  introPageSchema,
  IntroPageType,
  IStudyProcedure,
  ParticipantDetails,
  participantDetailsSchema,
  Study,
  studyQuestionSchema,
  studySchema,
  userStudyProcedureSchema,
  UserStudyProcedureType,
} from '../../models';
import { studyQuestionRuleSchema, StudyQuestionRuleType } from '../../models/rules';
import { StudyQuestion, StudyQuestions } from '../../models/study-question';
import { donateAqSchema, DonateAqType, iSurveyService, ResearchProcedure, researchProcedureSchema, userStudySchema, UserStudyType } from '../models';

/**
 * NOTE: You are supposed to do your changes in survey-public.service.ts if you are changing the public part
 */
@Injectable({providedIn: 'root'})
export class SurveyService implements iSurveyService {
  isPublicApplication = false;

  constructor(
    @Inject(REGISTRY_APPLICATION) public application: string,
    @Inject(REGISTRY_ADMIN_URL) private adminUrl: string,
    @Inject(REGISTRY_PUBLIC_URL) private publicUrl: string,
    @Inject(REGISTRY_PARTICIPANT_URL) private participantUrl: string,
    @Inject(REGISTRY_API_URL) private apiUrl: string,
    private http: HttpClient
  ) {}

  study(id: string): Observable<Study> {
    return this.http.get<Study>(`${this.adminUrl}/studies/${id}`).pipe(
      schemaValidationPipe(studySchema, studySchema.name)
    );
  }

  loadProcedures(studyId: string): Observable<IStudyProcedure[]> {
    return this.http.get<Array<BEStudyProcedure>>(`${this.apiUrl}/studies/${studyId}/procedures`).pipe(
      schemaValidationPipe(t.array(beStudyProcedureSchema)),
      map(sca => sca.map(({procedure_id, created_at, order, title}) => ({
        id: procedure_id, created_at, order, title,
      }))),
    );
  }

  studyUserProcedures(participantId: string, userStudyId: string): Observable<UserStudyProcedureType[]> {
    return this.http.get<UserStudyProcedureType[]>(
      `${this.participantUrl}/${participantId}/user-studies/${userStudyId}/user-procedures`
    ).pipe(
      schemaValidationPipe(t.array(userStudyProcedureSchema), userStudyProcedureSchema.name),
    );
  }

  loadQuestions(participantType: string, studyId: string): Observable<Array<StudyQuestion>> {
    return this.http.get<{questions: StudyQuestions}>(
      `${this.apiUrl}/studies/${studyId}/profile-type/${participantType}/questions`
    ).pipe(
      schemaValidationPipe(t.type({questions: nullable(t.array(studyQuestionSchema))})),
      map(({questions}) => isRealValue(questions) ? questions.map(question => new StudyQuestion(question)) : [])
    );
  }

  loadQuestionsRules(id: string): Observable<StudyQuestionRuleType[]> {
    return this.http.get<StudyQuestionRuleType[]>(`${this.apiUrl}/studies/${id}/question_rules`).pipe(
      schemaValidationPipe(t.array(studyQuestionRuleSchema))
    );
  }

  loadAnswers(participantId: string, userStudyId: string | number, params?: {question_option: string}): Observable<StoredAnswerType[]> {
    return this.http.get<Array<StoredAnswerType>>(`${this.apiUrl}/participants/${participantId}/user-studies/${userStudyId}/answers`, {params}).pipe(
      schemaValidationPipe(t.array(storedAnswerSchema))
    );
  }

  saveAnswers(participantId: string, userStudyId: string | number, answers: StoredAnswersType, questionId: string): Observable<StoredAnswersType> {
    const url = `${this.apiUrl}/participants/${participantId}/user-studies/${userStudyId}/questions/${questionId}/answers?get_all_answers`;

    return this.http.post<StoredAnswersType>(url, answers).pipe(
      schemaValidationPipe(t.array(storedAnswerSchema))
    );
  }

  submitProcedure(participantId: string, userStudyId: string | number, procedure_id: string): Observable<UserStudyProcedureType> {
    return this.http.post(
      `${this.participantUrl}/${participantId}/user-studies/${userStudyId}/user-procedures`, {procedure_id}
    ).pipe(
      schemaValidationPipe(userStudyProcedureSchema, userStudyProcedureSchema.name),
    );
  }

  loadIntroPages(studyId: string): Observable<IntroPageType[]> {
    return this.http.get<IntroPageType[]>(`${this.apiUrl}/studies/${studyId}/intro-pages`).pipe(
      schemaValidationPipe(t.array(introPageSchema), introPageSchema.name)
    );
  }

  loadConsents(participantId: string, userStudyId: string | number): Observable<Array<Consent>> {
    return this.http.get<Array<Consent>>(`${this.participantUrl}/${participantId}/user-studies/${userStudyId}/consents`).pipe(
      schemaValidationPipe(t.array(consentSchema), consentSchema.name + '[]')
    );
  }

  loadConsentBody(consentId: number): Observable<ConsentBody> {
    return this.http.get<ConsentBody>(`${this.apiUrl}/consents/${consentId}`).pipe(
      schemaValidationPipe(consentBodySchema)
    );
  }

  loadResearchBody(participantId: string, userStudyId: string, studyId?: string): Observable<ResearchProcedure[]> {
    const url = isRealValue(studyId)
      ? `${this.apiUrl}/promotions?study_id=${studyId}`
      : `${this.participantUrl}/${participantId}/user_study/${userStudyId}/promotions`;

    return this.http.get<ResearchProcedure[]>(url).pipe(
      schemaValidationPipe(t.array(researchProcedureSchema), researchProcedureSchema.name)
    );
  }

  loadSignedConsentBody(userId: string, consentId: string): Observable<SignedConsent> {
    return this.http.get<SignedConsent>(`${this.participantUrl}/${userId}/user-consents/${consentId}`).pipe(
      schemaValidationPipe(signedConsentSchema)
    );
  }

  signConsent(
    participantId: string, userStudyId: number | string, consentId: number, bodyPayload: ConsentSignType
  ): Observable<ConsentStatusType> {
    const url = `${this.participantUrl}/${participantId}/user-studies/${userStudyId}/consents/${consentId}/sign`;

    return this.http.post<ConsentStatusType>(url, bodyPayload).pipe(schemaValidationPipe(consentStatusSchema));
  }

  getParticipantDetails(id: string): Observable<ParticipantDetails> {
    return this.http.get<ParticipantDetails>(`${this.participantUrl}/${id}`).pipe(
      schemaValidationPipe(participantDetailsSchema)
    );
  }

  getUserStudy(participantId: string, userStudyId: string | number): Observable<UserStudyType> {
    return this.http.get<UserStudyType>(`${this.participantUrl}/${participantId}/user-studies/${userStudyId}`).pipe(
      schemaValidationPipe(userStudySchema, userStudySchema.name),
    );
  }

  getUserStudies(participantId: string): Observable<UserStudyType[]> {
    return this.http.get<UserStudyType[]>(`${this.participantUrl}/${participantId}/user-studies`).pipe(
      schemaValidationPipe(t.array(userStudySchema), userStudySchema.name + '[]'),
    );
  }

  loadModuleSettings(studyId: string): Observable<ModuleSettings[]> {
    return this.http.get<ModuleSettings[]>(`${this.apiUrl}/v2/ui-module-settings`, {params: {study_id: studyId}});
  }

  donateAq(studyId: string): Observable<any> {
    const url = `${this.apiUrl}/aq_donation`;
    return this.http.post<any>(url, { 'study_id': studyId }).pipe(
        schemaValidationPipe(donateAqSchema)
    );
  }

  emailStudyToggle(participant_id: string, study_id: string,email_notification_toggle:boolean): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/${participant_id}/update_study/${study_id}`, {email_notification_toggle});
  }

  getDataInsightsChartsData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/live_data_givebacks`)
  }
}
