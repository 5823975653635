import { createAction, props } from '@ngrx/store';

import { BaseEventType, GroupedSettingsByProcedure, ParticipantSourceType, ProcedureTemplateType, StoredAnswersType } from '@tr-common';

import { MinimalStudyType, ParticipantDetails, ProcedureQuestions, Procedures } from '../../../models';
import {
  DraftAnswersType,
  FullStudy,
  LoadFullStudy,
  LoadFullStudyStage2,
  ParamsIDs,
  SurveyPreselectType,
  UserStudyType,
} from '../../models';

export enum surveyActions {
  STUDY_RESET_STATE = '[Survey] Reset this store',
  GET_USER_STUDY = '[Survey] Get minimal information about the study',
  GET_USER_STUDY_SUCCESS = '[Survey] Got minimal information about the study success',
  GET_USER_STUDY_FAIL = '[Survey] Got minimal information about the study failed',
  CHECK_IF_STUDY_ALREADY_STARTED = '[Survey] Check if the study is already started',
  REGISTER_STARTED_STUDY = '[Survey] Register started study',
  REGISTER_STARTED_STUDY_SUCCESS = '[Survey] Registered started study success',
  REGISTER_STARTED_STUDY_FAIL = '[Survey] Registered started study fail',
  LOAD_STUDY_FULL = '[Survey] Load full study',
  LOAD_STUDY_FULL_STAGE2 = '[Survey] Load full study (second stage)',
  LOAD_STUDY_FULL_SUCCESS = '[Survey] Load full study success',
  LOAD_STUDY_FULL_FAIL = '[Survey] Load full study fail',
  REFRESH_STUDY_QUESTIONS = '[Survey] Refresh study questions only',
  REFRESH_STUDY_QUESTIONS_SUCCESS = '[Survey] Refreshed study questions success',
  REFRESH_STUDY_QUESTIONS_FAIL = '[Survey] Refreshed study questions fail',
  LOAD_USER_PROCEDURES_IF_NOT_ELIGIBLE_SUCCESS = '[Survey] Load user procedures success if a user is not eligible',
  LOAD_PARTICIPANT_DETAILS_SUCCESS = '[Survey] Loaded participant details success',
  LOAD_PARTICIPANT_DETAILS_FAIL = '[Survey] Loaded participant details fail',
  SET_ACTIVE_SURVEY = '[Survey] Set the active survey (including studyID, userStudyID, userID)',
  UPDATE_ACTIVE_SURVEY = '[Survey] Update the active survey by userStudyID',
  CHANGE_ACTIVE_PROCEDURE = '[Survey] Change active procedure in the reducer',
  CHANGE_ACTIVE_QUESTION = '[Survey] Change Active Question',
  GOTO_PROCEDURE = '[Survey] Go to the particular procedure',
  NEXT_PROCEDURE = '[Survey] Go to next procedure',
  CLICK_RESEARCH_PROCEDURE = '[Survey] The button "Start now" in the research procedure was clicked',
  CLICK_RESEARCH_PROCEDURE_SUCCESS = '[Survey] Registered user click in the research procedure success',
  LOAD_STUDY_ANSWERS = '[Survey] Load Study Answers',
  LOAD_STUDY_ANSWERS_SUCCESS = '[Survey] Load Study Answers Success',
  LOAD_STUDY_ANSWERS_FAIL = '[Survey] Load Study Answers Fail',
  LOAD_ANSWERS_FROM_ANOTHER_STUDY = '[Survey] Load Answers From Another Study',
  LOAD_ANSWERS_FROM_ANOTHER_STUDY_SUCCESS = '[Survey] Load Answers From Another Study Success',
  LOAD_ANSWERS_FROM_ANOTHER_STUDY_FAIL = '[Survey] Load Answers From Another Study Fail',
  SAVE_ANSWERS = '[Survey] Save Answers',
  SAVE_ANSWERS_SUCCESS = '[Survey] Save Answers Success',
  SAVE_ANSWERS_FAIL = '[Survey] Save Answers Fail',
  RESET_ANSWERS_SAVE_ERROR = '[Survey] Reset answers errors after parent question change',
  NEXT_QUESTION = '[Survey] Next Question',
  UPDATE_ABSENT_ANSWERS = '[Survey] Update absent answers after successful saving',
  NEXT_UNANSWERED_QUESTION = '[Survey] Next unanswered question',
  NEXT_UNANSWERED_QUESTION_AFTER_SUBMIT = '[Survey] Next unanswered question after unsuccessful try to submit procedure with questions',
  PREVIOUS_QUESTION = '[Survey] Previous Question',
  FINISH_PENDING = '[Survey] Pending is finished by flow',
  FINISH_SUBMIT_PENDING = '[Survey] Submit Pending is finished by flow',
  FINISH_PROCEDURE = '[Survey] Finish procedure and switch to submit page',
  NAVIGATE_TO_QUESTION = '[Survey] Navigate to the particular question',
  LOAD_USER_EVENTS_FOR_STUDY = '[Survey] Load user events for the current study',
  LOAD_USER_EVENTS_FOR_STUDY_SUCCESS = '[Survey] Load user events for the current study success',
  LOAD_USER_EVENTS_FOR_STUDY_FAIL = '[Survey] Load user events for the current study',
  REFRESH_HEADER_CATEGORIES = '[Survey] Refresh Header Categories',
  MARK_CATEGORIES_ENABLED = '[Survey] Mark Categories Enabled',
  CHECK_SHOWING_WELCOME_DIALOG = '[Survey] Check showing welcome dialog',
  CHECK_PRESELECT = '[Survey] Check if needs to select a procedure or question or consent',
  PRESELECT_PROCEDURE = '[Survey] Preselect procedure',
  PRESELECT_QUESTION = '[Survey] Preselect Question',
  PRESELECT_CONSENT = '[Survey] Preselect consent or auto-submit it',
  CHECK_STUDY_ELIGIBILITY = '[Survey] Check study eligibility for the participant',
  ROUTE_TO_NOT_ELIGIBLE_PAGE = '[Survey] Route to not-eligible page',
  CHECK_PARTICIPANT_ELIGIBILITY_NOT_ELIGIBLE = '[Survey] Check ParticipantEligibility - Not Eligible',
  LOAD_MODULE_SETTINGS = '[Survey] Load Module Settings',
  LOAD_MODULE_SETTINGS_SUCCESS = '[Survey] Load Module Settings Success',
  LOAD_MODULE_SETTINGS_FAIL = '[Survey] Load Module Settings Fail',
  PREVIEW_ANSWERS = '[FLOW] Preview answers before submit',
  REVIEW_ANSWERS = '[FLOW] Review answers after submit',
  START_SURVEY = '[Survey] Start survey from the scratch',
  RETURN_TO_DASHBOARD = '[FLOW] Return to dashboard',
  RESTART_SURVEY = '[Survey] Restart survey',
  DONATE_AQ_AMOUNT = '[Survey] Donate AQ amount',
  DONATE_AQ_AMOUNT_SUCCESS = '[Survey] Donate AQ amount success',
  DONATE_AQ_AMOUNT_FAIL = '[Survey] Donate AQ amount fail',
  EMAILSTUDYTURNOFF = '[Participant] Email Study Turnoff ',
  EMAILSTUDYTURNOFF_SUCCESS = '[Participant] Email Study Turnoff Success',
  EMAILSTUDYTURNOFF_FAIL = '[Participant] Email Study Turnoff Fail',
}

export const studyResetState = createAction(
  surveyActions.STUDY_RESET_STATE
);

export const getUserStudy = createAction(
  surveyActions.GET_USER_STUDY
);

export const getUserStudySuccess = createAction(
  surveyActions.GET_USER_STUDY_SUCCESS,
  props<{payload: UserStudyType}>()
);

export const getUserStudyFail = createAction(
  surveyActions.GET_USER_STUDY_FAIL,
  props<{error: unknown}>()
);

export const checkIfStudyAlreadyStarted = createAction(
  surveyActions.CHECK_IF_STUDY_ALREADY_STARTED
);

export const registerStartedStudy = createAction(
  surveyActions.REGISTER_STARTED_STUDY,
  props<ParamsIDs>()
);

export const registerStartedStudySuccess = createAction(
  surveyActions.REGISTER_STARTED_STUDY_SUCCESS,
  props<{payload: BaseEventType}>()
);

export const registerStartedStudyFail = createAction(
  surveyActions.REGISTER_STARTED_STUDY_FAIL,
  props<{error: unknown}>()
);

export const loadStudyFull = createAction(
  surveyActions.LOAD_STUDY_FULL,
  props<LoadFullStudy>()
);

export const loadStudyFullStage2 = createAction(
  surveyActions.LOAD_STUDY_FULL_STAGE2,
  props<LoadFullStudyStage2>()
);

export const loadStudyFullSuccess = createAction(
  surveyActions.LOAD_STUDY_FULL_SUCCESS,
  props<FullStudy>(),
);

export const loadStudyFullFail = createAction(
  surveyActions.LOAD_STUDY_FULL_FAIL,
  props<{error: unknown}>()
);

export const refreshStudyQuestions = createAction(
  surveyActions.REFRESH_STUDY_QUESTIONS
);

export const refreshStudyQuestionsSuccess = createAction(
  surveyActions.REFRESH_STUDY_QUESTIONS_SUCCESS,
  props<{payload: ProcedureQuestions}>()
);

export const refreshStudyQuestionsFail = createAction(
  surveyActions.REFRESH_STUDY_QUESTIONS_FAIL,
  props<{error: unknown}>()
);

export const loadStudyFullIfNotEligibleSuccess = createAction(
  surveyActions.LOAD_USER_PROCEDURES_IF_NOT_ELIGIBLE_SUCCESS,
  props<FullStudy>()
);

export const loadParticipantDetailsSuccess = createAction(
  surveyActions.LOAD_PARTICIPANT_DETAILS_SUCCESS,
  props<{payload: ParticipantDetails}>(),
);

export const loadParticipantDetailsFail = createAction(
  surveyActions.LOAD_PARTICIPANT_DETAILS_FAIL,
  props<{payload: any}>(),
);

export const setActiveSurvey = createAction(
  surveyActions.SET_ACTIVE_SURVEY,
  props<{payload: Partial<ParamsIDs>}>(),
);

export const updateActiveSurvey = createAction(
  surveyActions.UPDATE_ACTIVE_SURVEY,
  props<{userStudyID: string}>(),
);

export const changeActiveProcedure = createAction(
  surveyActions.CHANGE_ACTIVE_PROCEDURE,
  props<{payload: string}>(),
);

export const donateAq = createAction(
  surveyActions.DONATE_AQ_AMOUNT,
  props<{studyId: string}>(),
);


export const donateAqSuccess = createAction(
  surveyActions.DONATE_AQ_AMOUNT_SUCCESS,
  props<{payload: any}>()
);

export const donateAqFailure = createAction(
  surveyActions.DONATE_AQ_AMOUNT_FAIL,
  props<{error: unknown}>()
);

export const changeActiveQuestion = createAction(
  surveyActions.CHANGE_ACTIVE_QUESTION,
  props<{payload: string}>(),
);

export const gotoProcedure = createAction(
  surveyActions.GOTO_PROCEDURE,
  props<{payload: string, isCompleted: boolean}>(),
);

export const nextProcedure = createAction(
  surveyActions.NEXT_PROCEDURE
);

export const clickResearchProcedure = createAction(
  surveyActions.CLICK_RESEARCH_PROCEDURE,
  props<{promotionID: string}>()
);

export const clickResearchProcedureSuccess = createAction(
  surveyActions.CLICK_RESEARCH_PROCEDURE_SUCCESS
);

export const loadAnswers = createAction(
  surveyActions.LOAD_STUDY_ANSWERS,
  props<{participantID: string, userStudyID: string}>(),
);

export const loadAnswersSuccess = createAction(
  surveyActions.LOAD_STUDY_ANSWERS_SUCCESS,
  props<{payload: {[key: string]: StoredAnswersType}}>(),
);

export const loadAnswersFail = createAction(
  surveyActions.LOAD_STUDY_ANSWERS_FAIL,
  props<{err: unknown}>(),
);

export const loadAnswersFromAnotherStudy = createAction(
  surveyActions.LOAD_ANSWERS_FROM_ANOTHER_STUDY,
  props<FullStudy>(),
);

export const loadAnswersFromAnotherStudySuccess = createAction(
  surveyActions.LOAD_ANSWERS_FROM_ANOTHER_STUDY_SUCCESS,
  props<{payload: {[key: string]: StoredAnswersType}}>(),
);

export const loadAnswersFromAnotherStudyFail = createAction(
  surveyActions.LOAD_ANSWERS_FROM_ANOTHER_STUDY_FAIL,
  props<{err: unknown}>(),
);

export const saveAnswers = createAction(
  surveyActions.SAVE_ANSWERS,
  props<{draftAnswers: DraftAnswersType, navigate: NavigateActions, preview: boolean}>()
);

export const saveAnswersSuccess = createAction(
  surveyActions.SAVE_ANSWERS_SUCCESS,
  props<{payload: {[key: string]: StoredAnswersType}}>()
);

export const saveAnswersFail = createAction(
  surveyActions.SAVE_ANSWERS_FAIL,
  props<{error: string[]}>()
);

export const resetAnswersSaveError = createAction(
  surveyActions.RESET_ANSWERS_SAVE_ERROR
);

export const nextQuestion = createAction(
  surveyActions.NEXT_QUESTION,
  props<{preview: boolean}>()
);

export const previousQuestion = createAction(
  surveyActions.PREVIOUS_QUESTION,
  props<{preview: boolean}>()
);

export const updateAbsentAnswers = createAction(
  surveyActions.UPDATE_ABSENT_ANSWERS,
  props<{savedAnswers: DraftAnswersType}>()
);

export const nextUnansweredQuestion = createAction(
  surveyActions.NEXT_UNANSWERED_QUESTION,
  props<{preview: boolean}>()
);

export const nextUnansweredQuestionAfterSubmit = createAction(
  surveyActions.NEXT_UNANSWERED_QUESTION_AFTER_SUBMIT
);

export const finishPending = createAction(
  surveyActions.FINISH_PENDING
);

export const finishSubmitPending = createAction(
  surveyActions.FINISH_SUBMIT_PENDING
);

export const finishProcedure = createAction(
  surveyActions.FINISH_PROCEDURE,
  props<{preview: boolean, message?: string}>()
);

export const navigateToQuestion = createAction(
  surveyActions.NAVIGATE_TO_QUESTION,
  props<{questionId: string}>()
);

export const loadUserEventsForStudy = createAction(
  surveyActions.LOAD_USER_EVENTS_FOR_STUDY,
  props<{procedure: ProcedureTemplateType}>()
);

export const loadUserEventsForStudySuccess = createAction(
  surveyActions.LOAD_USER_EVENTS_FOR_STUDY_SUCCESS,
  props<{procedure: ProcedureTemplateType, hasRegisteredEvents: boolean}>()
);

export const loadUserEventsForStudyFail = createAction(
  surveyActions.LOAD_USER_EVENTS_FOR_STUDY_FAIL,
  props<{payload: unknown}>()
);

export const refreshHeaderWithProcedures = createAction(
  surveyActions.REFRESH_HEADER_CATEGORIES
);

export const markProceduresEnabled = createAction(
  surveyActions.MARK_CATEGORIES_ENABLED,
  props<{payload: {answers: {[p: number]: StoredAnswersType}; procedures: Procedures}}>()
);

export const checkShowingWelcomeDialog = createAction(
  surveyActions.CHECK_SHOWING_WELCOME_DIALOG,
  props<{procedure: ProcedureTemplateType}>()
);

export const checkPreselect = createAction(
  surveyActions.CHECK_PRESELECT,
  props<{payload: SurveyPreselectType}>()
);

export const preselectProcedure = createAction(
  surveyActions.PRESELECT_PROCEDURE,
  props<{study: MinimalStudyType}>()
);

export const preselectConsent = createAction(
  surveyActions.PRESELECT_CONSENT
);

export const preselectQuestion = createAction(
  surveyActions.PRESELECT_QUESTION,
  props<{payload: SurveyPreselectType}>()
);

export const checkStudyEligibility = createAction(
  surveyActions.CHECK_STUDY_ELIGIBILITY,
  props<{study: FullStudy}>()
);

export const routeToNotEligiblePage = createAction(
  surveyActions.ROUTE_TO_NOT_ELIGIBLE_PAGE,
  props<{isCoreStudy: boolean}>()
);

export const checkParticipantEligibilityNotEligible = createAction(
  surveyActions.CHECK_PARTICIPANT_ELIGIBILITY_NOT_ELIGIBLE,
  props<{source: ParticipantSourceType}>()
);

export const loadModuleSettings = createAction(
  surveyActions.LOAD_MODULE_SETTINGS,
  props<{studyId: string, procedure: ProcedureTemplateType, showModal: boolean}>()
);

export const loadModuleSettingsSuccess = createAction(
  surveyActions.LOAD_MODULE_SETTINGS_SUCCESS,
  props<{moduleSettings: GroupedSettingsByProcedure, procedure?: ProcedureTemplateType, showModal: boolean}>()
);

export const loadModuleSettingsFail = createAction(
  surveyActions.LOAD_MODULE_SETTINGS_FAIL,
  props<{err: unknown}>()
);

export const flowPreviewAnswers = createAction(
  surveyActions.PREVIEW_ANSWERS
);

export const flowReviewAnswers = createAction(
  surveyActions.REVIEW_ANSWERS,
  props<{procedure: string}>()
);

export const startSurvey = createAction(
  surveyActions.START_SURVEY
);

export const returnToDashboard = createAction(
  surveyActions.RETURN_TO_DASHBOARD
);

export const restartSurvey = createAction(
  surveyActions.RESTART_SURVEY,
  props<ParamsIDs>()
);

export type NavigateActions = (typeof nextQuestion)
  | (typeof nextUnansweredQuestion)
  | (typeof nextUnansweredQuestionAfterSubmit)
  | (typeof previousQuestion)
  | (typeof returnToDashboard)
  | (typeof finishProcedure);

  export const emailStudyTurnOff = createAction(
    surveyActions.EMAILSTUDYTURNOFF,
    props<{participant_id: string, study_id: string , email_notification_toggle:boolean}>()
  );
  
  export const emailStudyTurnOffSuccess = createAction(
    surveyActions.EMAILSTUDYTURNOFF_SUCCESS,
  );
  
  export const  emailStudyTurnOffFail = createAction(
    surveyActions.EMAILSTUDYTURNOFF_FAIL,
    props<{err: unknown}>()
  );